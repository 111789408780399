import {
  GET_CHAT_MESSAGES_SUCCESS,
  CHAT_MESSAGE_RECEIVED,
  USER_TYPING_IN_CHAT,
  MARK_MESSAGE_AS_SEEN,
  GET_ALL_USER_CHATS,
  NEW_MESSAGE_SENT,
  NEW_CHAT_SENT,
  CHAT_MESSAGE_UPDATE,
  SET_LIVE_CHAT,
  REMOVE_LIVE_CHAT,
  CHAT_MESSAGE_STATUS_UPDATE,
  NEW_CHAT_RECEIVED
} from '../../../constants/actions';

/**
 * notificationsMessages are for the notifications in the dropdown menu
 * popupNotifications are for notifications that get displayed on screen on receiving a new notification
 */
const initialState = {
  // popupCount keeps track of the index each time a new notification gets added
  // As multiple notifications can appear at once, each notification has to have a unique index which does not change
  chats: {},
  liveChat: null
};

export default function reducer(state = initialState, action = {}) {
  // Add index to notification if it's a popup message. You cannot create variables inside case
  switch (action.type) {
    case NEW_CHAT_SENT:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.message.chat_id]: action.message
        }
      };
    case GET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.response.messages.chat_id]: action.response.messages
        }
      };
    case CHAT_MESSAGE_RECEIVED:
    case CHAT_MESSAGE_UPDATE:
      return {
        ...state,
        chats: state.chats
          ? {
              ...state.chats,
              [action.message.chat_id]: state.chats[action.message.chat_id]
                ? {
                    ...state.chats[action.message.chat_id],
                    chat_messages: {
                      ...state.chats[action.message.chat_id].chat_messages,
                      [action.message.id]: action.message
                    },
                    last_message: action.message.message,
                    last_message_time: action.message.created_at
                  }
                : null
            }
          : null
      };
    case NEW_MESSAGE_SENT:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.message.chat_id]: {
            ...state.chats[action.message.chat_id],
            chat_messages: {
              ...state.chats[action.message.chat_id].chat_messages,
              [action.message.id]: action.message
            },
            last_message: action.message.message,
            last_message_time: action.message.created_at
          }
        }
      };
    case USER_TYPING_IN_CHAT:
      return {
        ...state,
        chats: state.chats
          ? {
              ...state.chats,
              [action.message.notify_payload.chat_id]: state.chats[action.message.notify_payload.chat_id]
                ? {
                    ...state.chats[action.message.notify_payload.chat_id],
                    userTyping: action.message.notify_payload.typing
                  }
                : null
            }
          : null
      };
    case MARK_MESSAGE_AS_SEEN:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.id]: {
            ...state.chats[action.id],
            chat_messages: Object.fromEntries(
              Object.entries(state.chats[action.id].chat_messages).map(([messageId, message]) => [
                messageId,
                message.status === 'DELIVERED' && message.is_first_sender === 0
                  ? { ...message, status: 'SEEN' }
                  : message
              ])
            )
          }
        }
      };

    case CHAT_MESSAGE_STATUS_UPDATE:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.message.chat_id]: {
            ...state.chats[action.message.chat_id],
            chat_messages: Object.fromEntries(
              Object.entries(state.chats[action.message.chat_id].chat_messages).map(([messageId, message]) => [
                messageId,
                messageId === String(action.message.id) ? { ...message, status: action.message.status } : message
              ])
            )
          }
        }
      };
    case GET_ALL_USER_CHATS:
      return {
        chats: action.payload
      };
    case NEW_CHAT_RECEIVED:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.message.chat_id]: action.message
        }
      };
    case SET_LIVE_CHAT:
      return {
        ...state,
        liveChat: action.message
      };
    case REMOVE_LIVE_CHAT:
      return {
        ...state,
        liveChat: null
      };
    default:
      return state;
  }
}
