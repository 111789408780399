import api from '../../../assets/helpers/api';
import { GET_CHATS_REQUEST, GET_CHATS_FAILURE, GET_ALL_USER_CHATS } from '../../../constants/actions';
import { chatEndpoint } from '../../../constants/apiEndpoints';
import store from '../../store';

function getChatsRequest() {
  return { type: GET_CHATS_REQUEST };
}

function getChatsSuccess(response) {
  const preparedChats = response?.data?.chats?.reduce((acc, chat) => {
    const parsedChatMessages = JSON.parse(chat.chat_messages);
    const chat_messages = parsedChatMessages.reduce((msg, message) => {
      msg[message.id] = message;
      return msg;
    }, {});
    const chatObject = {
      ...chat,
      chat_messages
    };
    acc[chat.chat_id] = chatObject;
    return acc;
  }, {});

  return {
    type: GET_ALL_USER_CHATS,
    payload: preparedChats
  };
}

function getChatsError(error) {
  return {
    type: GET_CHATS_FAILURE,
    error
  };
}

export default function getChats(data = {}) {
  const method = 'GET';
  const { Ht, Ct, Et, userId } = store.getState().session;
  const companyId = store.getState().userCompanies?.selected?.id;

  const hData = {
    userId,
    Ht,
    Ct,
    Et,
    companyId
  };

  return (dispatch) => {
    dispatch(getChatsRequest());
    return api(chatEndpoint.GET_USER_CHATS, data, method, hData)
      .then((response) => dispatch(getChatsSuccess(response)))
      .catch((error) => dispatch(getChatsError(error)));
  };
}
