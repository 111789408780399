import { CHAT_MESSAGE_STATUS_UPDATE, CHAT_MESSAGE_UPDATE } from '../../../constants/actions';

function setChatMessageUpdate(message) {
  return {
    type: CHAT_MESSAGE_UPDATE,
    message
  };
}

function setChatMessageStatusUpdate(message) {
  return {
    type: CHAT_MESSAGE_STATUS_UPDATE,
    message
  };
}

export function chatMessageUpdate(message) {
  return (dispatch) => {
    return dispatch(setChatMessageUpdate(message));
  };
}

export function chatMessageStatusUpdate(message) {
  return (dispatch) => {
    return dispatch(setChatMessageStatusUpdate(message));
  };
}
