import { useEffect, useState } from 'react';
import config from '../constants/config';
import useAPI from './useAPI';
import { notificationsEndpoint } from '../constants/apiEndpoints';

/**
 * Custom hook to handle notification permissions and push subscription
 */
const useNotificationPermission = () => {
  console.log('🔑 Public VAPID Key:', config.REACT_APP_PUSH_PUBLIC_KEY);
  const [permission, setPermission] = useState(Notification.permission);
  const [subscription, setSubscription] = useState(null);

  // ✅ Use useAPI for sending subscription data
  const [subscribePush, , subscribeStatus, subscribeError] = useAPI({
    url: notificationsEndpoint.SUBSCRIBE_PUSH_NOTIFICATIONS,
    method: 'POST',
    callback: (response) => {
      console.log('✅ Push subscription successfully stored in the backend:', response);
    }
  });

  useEffect(() => {
    if (!('Notification' in window) || !('serviceWorker' in navigator)) {
      console.warn('⚠️ Notifications or Service Worker are not supported in this browser.');
      return;
    }

    // If permission is default, request permission
    if (permission === 'default') {
      console.log('🔔 Requesting notification permission...');
      Notification.requestPermission().then((newPermission) => {
        setPermission(newPermission);
        console.log(`🔔 New notification permission: ${newPermission}`);
      });
    }

    if (permission === 'granted') {
      console.log('🔔 Notifications granted, checking subscription...');

      navigator.serviceWorker.ready.then(async (registration) => {
        try {
          const existingSubscription = await registration.pushManager.getSubscription();

          if (existingSubscription) {
            console.log('✅ Existing push subscription found:', existingSubscription);
            setSubscription(existingSubscription);
          } else {
            console.log('📡 No existing subscription found. Creating a new one...');
            console.log('🔑 Public VAPID Key 2:', config.REACT_APP_PUSH_PUBLIC_KEY);
            // Create a new push subscription
            const newSubscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(config.REACT_APP_PUSH_PUBLIC_KEY)
            });

            console.log('✅ New Push Subscription Created:', newSubscription);
            setSubscription(newSubscription);

            // ✅ Send subscription data to API
            await subscribePush({
              endpoint: newSubscription.endpoint,
              publicKey: newSubscription.toJSON().keys.p256dh,
              authToken: newSubscription.toJSON().keys.auth,
              expirationTime: newSubscription.expirationTime || null
            });
          }
        } catch (error) {
          console.error('❌ Error retrieving/creating push subscription:', error);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission]);

  return { permission, subscription, subscribeStatus, subscribeError };
};

// Helper function to convert Base64 URL string to Uint8Array
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}

export default useNotificationPermission;
