import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { connect, useDispatch, useSelector } from 'react-redux';
import AppComponentContext from '../../../../../contexts/AppComponentContext';
import Button from '../../../../UI/Button/Button';
import styles from './AgreementContainer.module.css';
import useAPI, { apiStatus } from '../../../../../hooks/useAPI';
import { agreementEndpoint, companyEndpoint } from '../../../../../constants/apiEndpoints';
import { authenticatedRoutes } from '../../../../../constants/paths';
import ListDocuments from '../ListDocuments/ListDocuments';
import StickyFooter, { FooterButtons } from '../../../../UI/StickyFooter/StickyFooter';
import CmrGenerator from '../../../../UI/CmrGenerator/CmrGenerator';
import FormDrawer from '../../../../UI/FormDrawer/FormDrawer';
import { useWindowDimensions } from '../../../../../assets/helpers/useWindowDimensions';
import ButtonSelect from '../../../../UI/ButtonSelect/ButtonSelect';
import CmrTheme from '../../../../UI/CmrGenerator/CmrTheme';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import TSA from '../../../LegalDocuments/TSA/TSA';
import { getPathWithParams } from '../../../../../assets/helpers/common';
import { wholePageLoaderHide, wholePageLoaderShow } from '../../../../../store/actions/wholePageLoaderState';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import CmrGeneratorAgreement from '../../../../UI/CmrGenerator/CmrGeneratorAgreement';
import { parsePackagingMethod } from '../../../../../constants/packagingMethods';
import ActionInfo from '../../../../UI/ActionInfo/ActionInfo';
import { openDrawer } from 'src/store/actions/Drawers';

const pageStyle = `
  @page {
    size: A4 portrait;
    margin: 0mm !important;
  }

  body {
    margin: 0px
  }
`;

export const agreementType = {
  VEHICLE: 1,
  CARGO: 2,
  WAREHOUSE: 3
};

const getAgreementUrl = (type) => {
  switch (type) {
    case agreementType.VEHICLE:
      return agreementEndpoint.FIND_AGREEMENT;
    case agreementType.CARGO:
      return agreementEndpoint.FIND_AGREEMENT_CARGO;
    case agreementType.WAREHOUSE:
      return agreementEndpoint.FIND_AGREEMENT_WAREHOUSE;
    default:
      break;
  }
};

const AgreementContainer = ({ Agreement, userId, selectedCompany, type }) => {
  const dispatch = useDispatch();
  const cmrMainRef = useRef();
  const cmrRed = useRef();
  const cmrGreen = useRef();
  const cmrBlue = useRef();
  const cmrBlack = useRef();
  const localization = useContext(AppComponentContext).strings;
  const location = useLocation();
  const { isPC } = useWindowDimensions();
  const { state } = location;
  const { rated: isRated } = state || {};
  const cmrRef = useRef();
  const [cmrDrawer, setCmrDrawer] = useState(false);
  const [printState, setPrintState] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [cmrType, setCmrType] = useState('all');
  const [theme, setTheme] = useState('black');
  const [printAllCmr, setPrintAllCmr] = useState(true);
  const [downloadState, setDownloadState] = useState(false);
  const [downloadDropdown, setDownloadDropdown] = useState(false);
  const [tsaDrawer, setTsaDrawer] = useState(false);
  const [agreementRated, setAgreementRated] = useState(false);
  const [showActionInfo, setShowActionInfo] = useState(false);
  const navigate = useNavigate();

  const agreementRef = useRef();
  const handlePrint = useReactToPrint({ content: () => agreementRef.current, pageStyle: pageStyle });
  const handlePrintCmr = useReactToPrint({
    content: () => cmrRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      setPrintState(false);
      setPrintAllCmr(false);
    }
  });

  const { agreement_id } = useSelector(({ drawers }) => drawers?.viewAgreementDrawer?.data);
  const { agreementId: paramId } = useParams();

  const agreementId = agreement_id || paramId;

  const [getAgreement, agreement, agreementStatus] = useAPI({
    url: getPathWithParams(getAgreementUrl(type), { id: agreementId }),
    method: 'GET'
  });

  const [generateCmr, , generateCmrStatus, generateCmrError] = useAPI(
    {
      url: companyEndpoint.GENERATE_CMR_AGREEMENT,
      method: 'POST'
    },
    []
  );

  const { rated, offer_documents, bid_documents, sender, receiver, pickup_date, packages, canceled_agreement } =
    agreement || {};

  const senderReceiverParams = { sender, receiver, pickup_date };
  const hasCmrGenerator = sender && receiver ? true : false;
  const shouldShowActionInfo = generateCmrStatus !== apiStatus.IDLE && generateCmrStatus !== apiStatus.FAIL;

  const handleCmrTextAndTheme = useMemo(() => {
    switch (cmrType) {
      case 'all':
        setTheme('black');
        return localization.printAll;
      case 'black':
        setTheme('black');
        return localization.printCmrBlack;
      case 'red':
        setTheme('red');
        return localization.printCmrRed;
      case 'green':
        setTheme('green');
        return localization.printCmrGreen;
      case 'blue':
        setTheme('blue');
        return localization.printCmrBlue;
      case 'copy':
        setTheme('blackCopy');
        return localization.printCmrBlack;
    }
  }, [
    cmrType,
    localization.printAll,
    localization.printCmrBlack,
    localization.printCmrRed,
    localization.printCmrGreen,
    localization.printCmrBlue
  ]);

  const handleCmrDownloadText = useMemo(() => {
    switch (cmrType) {
      case 'all':
        setTheme('black');
        return localization.downloadAll;
      case 'black':
        setTheme('black');
        return localization.downloadBlack;
      case 'red':
        setTheme('red');
        return localization.downloadRed;
      case 'green':
        setTheme('green');
        return localization.downloadGreen;
      case 'blue':
        setTheme('blue');
        return localization.downloadBlue;
      case 'copy':
        setTheme('blackCopy');
        return localization.downloadBlack;
    }
  }, [
    cmrType,
    localization.downloadAll,
    localization.downloadBlack,
    localization.downloadBlue,
    localization.downloadGreen,
    localization.downloadRed
  ]);

  const selectCmrType = useCallback((name) => {
    setDropdown(false);
    setCmrType(name);
  }, []);

  const handleRedirect = useCallback(() => {
    if (agreementStatus === 'FAIL' && agreement) {
      navigate(authenticatedRoutes.COMPANY_AGREEMENTS);
    }
  }, [agreement, agreementStatus, navigate]);

  const captureElement = useCallback(async (ref) => {
    const canvas = await html2canvas(ref, { scale: 2 });
    return canvas;
  }, []);

  const asyncPrintHandler = useCallback(() => {
    setPrintState(true);
    return () => setPrintState(false);
  }, []);

  const asyncDownloadHandler = useCallback(async () => {
    await setDownloadState(true);
    return () => setDownloadState(false);
  }, []);

  const cmrDefaultCargo = {
    generator_packages: [
      {
        name: '',
        consolidation_name: '',
        num_of_units: '',
        unit_weight: '',
        packaging_method: '',
        incoterms: ''
      }
    ]
  };

  const cmrTableValues = {
    sender: {
      carriage: '',
      reductions: '',
      balance: '',
      supplement_charges: '',
      additional_charges: '',
      miscellaneous: '',
      to_be_paid: ''
    },
    receiver: {
      carriage: '',
      reductions: '',
      balance: '',
      supplement_charges: '',
      additional_charges: '',
      miscellaneous: '',
      to_be_paid: ''
    },

    currency: {
      sender: {
        carriage: '',
        reductions: '',
        balance: '',
        supplement_charges: '',
        additional_charges: '',
        miscellaneous: '',
        to_be_paid: ''
      },
      receiver: {
        carriage: '',
        reductions: '',
        balance: '',
        supplement_charges: '',
        additional_charges: '',
        miscellaneous: '',
        to_be_paid: ''
      }
    }
  };

  const cmrTemplate = {
    ...cmrDefaultCargo,
    ...cmrTableValues,
    pickup_point: '',
    delivery_point: '',
    company_name: '',
    remarks: '',
    cargo_description: '',
    sender_info: '',
    cash_on_delivery: '',
    uploaded_documents: '',
    receiver_observation: ''
  };

  const defaultValues = {
    ...cmrTemplate
  };

  const formMethods = useForm({
    defaultValues: defaultValues
  });
  const { getValues, control, reset, setValue, handleSubmit } = formMethods;

  const onSubmit = useCallback(
    (data) => {
      const { cmr_template } = data;
      const sender = {
        cargo_description: cmr_template?.cargo_description,
        cash_on_delivery: cmr_template?.cash_on_delivery,
        delivery_point: cmr_template?.delivery_point,
        generator_packages: cmr_template?.generator_packages,
        incoterms: cmr_template?.incoterms,
        pickup_point: cmr_template?.pickup_point,
        remarks: cmr_template?.remarks,
        company_name: cmr_template?.company_name,
        sender_info: cmr_template?.sender_info,
        uploaded_documents: cmr_template?.uploaded_documents,
        sender_table: cmr_template?.sender,
        sender_currency: cmr_template?.currency?.sender
      };

      const receiver = {
        receiver_company_name: cmr_template?.receiver_info,
        receiver_remarks: cmr_template?.receiver_remarks,
        receiver_table: cmr_template?.receiver,
        receiver_currency: cmr_template?.currency?.receiver,
        receiver_observation: cmr_template?.receiver_observation
      };

      const preparedData = {
        sender: JSON.stringify(sender),
        receiver: JSON.stringify(receiver),
        cmr_type: type,
        agreement_id: agreementId
      };

      generateCmr(preparedData);
    },
    [agreementId, generateCmr, type]
  );

  const { remove, fields, append } = useFieldArray({ name: 'cmr_template.generator_packages', control });

  const handleDrawerClose = useCallback(() => {
    setDownloadDropdown(false);
    setDropdown(false);
    showActionInfo && getAgreement();
    reset();
  }, [reset, getAgreement, showActionInfo]);

  const [getAgreementRating, agreementRating] = useAPI({
    url: getPathWithParams(agreementEndpoint.SEARCH_AGREEMENT_RATING, {
      id: agreementId
    }),
    method: 'GET'
  });
  const { bid_company, offeror_company } = agreementRating || {};
  const { bidder_id, id: bid_company_id } = bid_company || {};
  const { offeror_id, id: offeror_company_id } = offeror_company || {};

  const isBidder = userId === bidder_id && selectedCompany?.id === bid_company_id;
  const isOfferer = userId === offeror_id && selectedCompany.id === offeror_company_id;

  useEffect(() => {
    getAgreementRating({ type: type });
  }, [getAgreementRating, type]);

  useEffect(() => {
    getAgreement();
  }, [getAgreement]);

  useEffect(() => {
    if (rated) {
      setAgreementRated(Boolean(Number(rated)));
    }
  }, [rated]);

  useEffect(() => {
    if (isRated) setAgreementRated(true);
  }, [isRated]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  useEffect(() => {
    if (packages) {
      packages.forEach((el) => {
        append({
          ...el,
          packaging_method: parsePackagingMethod(el.packaging_method, localization, {
            width: el.width,
            height: el.height
          })
        });
      });
    }
  }, [append, localization, packages, setValue]);

  useEffect(() => {
    shouldShowActionInfo && setShowActionInfo(true);
  }, [shouldShowActionInfo]);

  useEffect(() => {
    if (printState) {
      handlePrintCmr();
    } else if (downloadState) {
      const pdfRef = new jsPDF('p', 'mm', 'a4', true);

      const downloadPdfHandler = async (cmrRef, disableLastPage = false) => {
        const mainCmr = await captureElement(cmrRef.current);
        let imgWidth = 210;
        let imgHeight = (mainCmr.height * imgWidth) / mainCmr.width;
        const imgData = await mainCmr.toDataURL('img/png');
        await pdfRef.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
        cmrType === 'all' && !disableLastPage && pdfRef.addPage();
      };

      (async () => {
        dispatch(wholePageLoaderShow());
        await downloadPdfHandler(cmrMainRef);
        if (cmrType === 'all') {
          await downloadPdfHandler(cmrRed);
          await downloadPdfHandler(cmrBlue);
          await downloadPdfHandler(cmrGreen);
          await downloadPdfHandler(cmrBlack, true);
        }
        await pdfRef.save('cmr_template');
        setDownloadState(false);
      })();
    }
    return () => {
      setPrintState(false);
      dispatch(wholePageLoaderHide());
    };
  }, [captureElement, cmrType, dispatch, downloadState, handlePrintCmr, printState]);

  useEffect(() => {
    if (dropdown) {
      setDownloadDropdown(false);
    }
  }, [downloadDropdown, dropdown]);

  return (
    <>
      <div className={styles.agreementContainer}>
        <Agreement ref={agreementRef} data={agreement} type={type} />
        <div className={`${styles.documentsAndControls} ${!isPC ? styles.documentsAndControlsResponsive : ''}`}>
          <ListDocuments
            documents={[...(offer_documents || []), ...(bid_documents || [])]}
            hasCmrGenerator={hasCmrGenerator}
            setCmrDrawer={setCmrDrawer}
          />
          {type === agreementType.VEHICLE || type === agreementType.CARGO ? (
            <div className={styles.transportServiceAgreement} onClick={() => setTsaDrawer(true)}>
              <p>{localization.transportServiceAgreement}</p>
            </div>
          ) : null}

          <StickyFooter>
            <FooterButtons>
              {(type === agreementType.VEHICLE || type === agreementType.CARGO) &&
              !hasCmrGenerator &&
              Boolean(agreement?.my_agreement) &&
              Boolean(!canceled_agreement) ? (
                <Button
                  onClick={() => {
                    setCmrDrawer(true);
                    reset(getValues());
                  }}
                  buttonType='success'
                >
                  {localization.generateCMR}
                </Button>
              ) : null}
              {!canceled_agreement ? (
                <Button onClick={handlePrint} buttonType='primaryOutlined'>
                  {localization.printPage}
                </Button>
              ) : null}
              {isBidder || isOfferer ? (
                <Button
                  onClick={() =>
                    dispatch(openDrawer(true, 'viewRateAgreementDrawer', { agreementId, agreementType: type }))
                  }
                  buttonType={'tertiary'}
                >
                  {agreementRated ? localization.viewRate : localization.rateAgreement}
                </Button>
              ) : (
                <>
                  <Button disabled disabledTooltipText={localization.disabledRateAgreementTooltip}>
                    {localization.rateAgreement}
                  </Button>
                </>
              )}
            </FooterButtons>
          </StickyFooter>
        </div>
      </div>
      {tsaDrawer ? (
        <FormDrawer closeDrawer={setTsaDrawer} formTitle={localization.transportServiceAgreement}>
          <TSA data={agreement} />
        </FormDrawer>
      ) : null}
      {cmrDrawer ? (
        <FormDrawer
          closeDrawer={setCmrDrawer}
          onClose={handleDrawerClose}
          formTitle={!hasCmrGenerator ? localization.generateCMR : localization.CMR}
        >
          <FormProvider {...formMethods}>
            <div
              className={[
                isPC ? styles.cmrWrapper : styles.cmrWrapperResponsive,
                showActionInfo && isPC ? styles.resizeDrawer : ''
              ].join(' ')}
            >
              {showActionInfo ? (
                <div className={styles.actionInfo}>
                  <ActionInfo
                    apiStatus={generateCmrStatus}
                    btnText={localization.ok}
                    btnFuction={() => {
                      setCmrDrawer(false);
                      setShowActionInfo(false);
                      getAgreement();
                    }}
                    message={
                      generateCmrError?.[0]?.error_code
                        ? localization[generateCmrError?.[0]?.error_code]
                        : localization.cmrSuccess
                    }
                  />
                </div>
              ) : (
                <>
                  <div
                    ref={cmrRef}
                    className={styles.printPage}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      overflowY: 'auto'
                    }}
                  >
                    <div ref={cmrMainRef}>
                      {hasCmrGenerator ? (
                        <CmrGenerator
                          view
                          printState={printState}
                          downloadState={downloadState}
                          senderReceiverParams={senderReceiverParams}
                          agreementInfo={agreement}
                          theme={theme}
                          customHeightClass={styles.customHeightClass}
                        />
                      ) : (
                        <CmrGeneratorAgreement
                          printState={printState}
                          downloadState={downloadState}
                          theme={theme}
                          agreementData={agreement}
                          fields={fields}
                          remove={remove}
                          append={append}
                          type={type}
                        />
                      )}
                    </div>
                    {printAllCmr ? (
                      <div className={`${!downloadState ? styles.hidden : ''}`}>
                        <div ref={cmrRed}>
                          <CmrTheme theme={'red'} downloadState={downloadState} />
                        </div>
                        <div ref={cmrBlue}>
                          <CmrTheme theme={'blue'} downloadState={downloadState} />
                        </div>
                        <div ref={cmrGreen}>
                          <CmrTheme theme={'green'} downloadState={downloadState} />
                        </div>
                        <div ref={cmrBlack}>
                          <CmrTheme theme={'blackCopy'} downloadState={downloadState} printState={printState} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {isPC ? (
                    <div className={styles.documentControls}>
                      <StickyFooter className={styles.flexClass}>
                        <ButtonSelect
                          onClick={asyncDownloadHandler}
                          dropdown={downloadDropdown}
                          setDropdown={setDownloadDropdown}
                          title={handleCmrDownloadText}
                          buttonType={'primaryOutline'}
                        >
                          <div className={styles.contentContainer}>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('all');
                                setPrintAllCmr(true);
                                setDownloadDropdown(false);
                              }}
                            >
                              {localization.downloadAll}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('black');
                                setPrintAllCmr(false);
                                setDownloadDropdown(false);
                              }}
                            >
                              {localization.copyForSender}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('red');
                                setPrintAllCmr(false);
                                setDownloadDropdown(false);
                              }}
                            >
                              {localization.copyForConsignee}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('blue');
                                setPrintAllCmr(false);
                                setDownloadDropdown(false);
                              }}
                            >
                              {localization.copyForCarrier}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('green');
                                setPrintAllCmr(false);
                                setDownloadDropdown(false);
                              }}
                            >
                              {localization.copyGreen}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('copy');
                                setPrintAllCmr(false);
                                setDownloadDropdown(false);
                              }}
                            >
                              {localization.copyBlack}
                            </div>
                          </div>
                        </ButtonSelect>
                        <ButtonSelect
                          onClick={asyncPrintHandler}
                          dropdown={dropdown}
                          setDropdown={setDropdown}
                          title={handleCmrTextAndTheme}
                          buttonType={'primaryOutline'}
                        >
                          <div className={styles.contentContainer}>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('all');
                                setPrintAllCmr(true);
                              }}
                            >
                              {localization.printAll}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('black');
                                setPrintAllCmr(false);
                              }}
                            >
                              {localization.copyForSender}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('red');
                                setPrintAllCmr(false);
                              }}
                            >
                              {localization.copyForConsignee}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('blue');
                                setPrintAllCmr(false);
                              }}
                            >
                              {localization.copyForCarrier}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('green');
                                setPrintAllCmr(false);
                              }}
                            >
                              {localization.copyGreen}
                            </div>
                            <div
                              className={styles.element}
                              onClick={() => {
                                selectCmrType('copy');
                                setPrintAllCmr(false);
                              }}
                            >
                              {localization.copyBlack}
                            </div>
                          </div>
                        </ButtonSelect>
                        {!hasCmrGenerator ? (
                          <Button onClick={() => handleSubmit(onSubmit)()} buttonType={'success'} disabled={false}>
                            {localization.saveAndAttach}
                          </Button>
                        ) : null}
                      </StickyFooter>
                    </div>
                  ) : (
                    <div className={styles.responsiveTest}>
                      <ButtonSelect
                        onClick={asyncDownloadHandler}
                        dropdown={downloadDropdown}
                        setDropdown={setDownloadDropdown}
                        title={handleCmrDownloadText}
                        buttonType={'primaryOutline'}
                      >
                        <div className={styles.contentContainer}>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('all');
                              setPrintAllCmr(true);
                              setDownloadDropdown(false);
                            }}
                          >
                            {localization.downloadAll}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('black');
                              setPrintAllCmr(false);
                              setDownloadDropdown(false);
                            }}
                          >
                            {localization.copyForSender}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('red');
                              setPrintAllCmr(false);
                              setDownloadDropdown(false);
                            }}
                          >
                            {localization.copyForConsignee}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('blue');
                              setPrintAllCmr(false);
                              setDownloadDropdown(false);
                            }}
                          >
                            {localization.copyForCarrier}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('green');
                              setPrintAllCmr(false);
                              setDownloadDropdown(false);
                            }}
                          >
                            {localization.copyGreen}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('copy');
                              setPrintAllCmr(false);
                              setDownloadDropdown(false);
                            }}
                          >
                            {localization.copyBlack}
                          </div>
                        </div>
                      </ButtonSelect>
                      <ButtonSelect
                        onClick={asyncPrintHandler}
                        dropdown={dropdown}
                        setDropdown={setDropdown}
                        title={handleCmrTextAndTheme}
                        buttonType={'primaryOutline'}
                      >
                        <div className={styles.contentContainer}>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('all');
                            }}
                          >
                            {localization.printAll}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('black');
                              setPrintAllCmr(false);
                            }}
                          >
                            {localization.copyForSender}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('red');
                              setPrintAllCmr(false);
                            }}
                          >
                            {localization.copyForConsignee}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('blue');
                              setPrintAllCmr(false);
                            }}
                          >
                            {localization.copyForCarrier}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('green');
                              setPrintAllCmr(false);
                            }}
                          >
                            {localization.copyGreen}
                          </div>
                          <div
                            className={styles.element}
                            onClick={() => {
                              selectCmrType('copy');
                              setPrintAllCmr(false);
                            }}
                          >
                            {localization.copyBlack}
                          </div>
                        </div>
                      </ButtonSelect>
                      {!hasCmrGenerator ? (
                        <Button onClick={() => handleSubmit(onSubmit)()} buttonType={'success'} disabled={false}>
                          {localization.saveAndAttach}
                        </Button>
                      ) : null}
                    </div>
                  )}
                </>
              )}
            </div>
          </FormProvider>
        </FormDrawer>
      ) : null}
    </>
  );
};

function mapStateToProps({ session, userCompanies }) {
  return {
    userId: session.userId,
    selectedCompany: userCompanies?.selected
  };
}
export default connect(mapStateToProps)(AgreementContainer);
