import io from 'socket.io-client';
import config from '../../constants/config';
import store from '../../store/store';

// actions
import { messageReceived } from '../../store/actions/Socket/messageReceived';
import { notificationMessageReceived } from '../../store/actions/Socket/notificationMessageReceived';
import { chatMessageReceived } from '../../store/actions/Socket/chatMessageReceived';
import { typingChatMessage } from '../../store/actions/Socket/typingChatMessage';
import { notificationNotifyReceived } from '../../store/actions/Socket/notificationNotifyReceived';
import { selectCompany } from '../../store/actions/User/userCompanies';
import logout from '../../store/actions/logout';
import hotReloadObserver from '../../store/actions/hotReloadObserver';
import setMinVersion from '../../store/actions/Socket/setMinVersion';
import { chatMessageStatusUpdate, chatMessageUpdate } from '../../store/actions/Socket/chatMessageUpdate';
import getChats from '../../store/actions/Socket/getChats';
import { newChatReceived } from '../../store/actions/Socket/newChatReceived';

let instance;

class Socket {
  static getInstance = (settings) => {
    if (config.ENABLE_SOCKET_SERVER) {
      if (!instance) {
        instance = new Socket(new SingletonInstance(), settings);
      }
      return instance;
    } else {
      return false;
    }
  };

  static logout = (hash) => {
    if (config.ENABLE_SOCKET_SERVER && instance) {
      instance.emit('logout', hash);
      instance.close();
      instance = null;
    }
  };

  constructor(singletonInstance, settings) {
    if (!(singletonInstance instanceof SingletonInstance)) {
      throw new Error('This is a singleton class, use getInstance method please.');
    }
    instance = io.connect(config.WS_API_URL, { ...config.SOCKET_SETTINGS, ...{ ...settings } });
    this.onGetMessages();
    this.onNotify();
    this.onServerMessage();
    this.onLogout();
    return instance;
  }

  onGetMessages = () => {
    if (config.ENABLE_SOCKET_SERVER) {
      instance.on('message', (message) => {
        store.dispatch(messageReceived(`Custom message! ${message}`));
      });
    }
  };

  onServerMessage = () => {
    if (config.ENABLE_SOCKET_SERVER) {
      instance.on('server_message', (message) => {
        if (message?.min_front_version) {
          store.dispatch(setMinVersion(message.min_front_version));
        }
      });
    }
  };

  onNotify = () => {
    if (config.ENABLE_SOCKET_SERVER) {
      instance.on('notify', (notification) => {
        const selectedCompany = store.getState().userCompanies?.selected?.id;

        const { notify_type, notify_payload } = notification;
        const { receiver_company, data } = notify_payload;

        switch (notify_type) {
          case 'message':
            // null because we have some notifications that are not connected to company
            if (receiver_company === null || receiver_company == selectedCompany) {
              store.dispatch(notificationMessageReceived(notification));
              store.dispatch(notificationNotifyReceived(notification));

              hotReloadObserver.notify(notify_payload);
            }
            break;

          case 'notify':
            store.dispatch(notificationNotifyReceived(notification));
            break;

          case 'company_changed':
            store.dispatch(selectCompany(data));
            break;

          case 'server_message':
            break;
          default:
            break;
        }
      });

      instance.on('chat', (message) => {
        const { notify_type, notify_payload } = message;
        const { receiver_company, sender_company, sender } = notify_payload;
        const selectedCompany = store.getState().userCompanies?.selected?.id;
        const userId = store.getState().session?.userId;
        if (receiver_company != null && receiver_company == selectedCompany) {
          switch (notify_type) {
            case 'chat_message':
              if (message.notify_payload.chat) {
                const data = message.notify_payload.chat;
                const messages = JSON.parse(data.chat_messages).reduce((acc, item) => {
                  acc[item.id] = item;
                  return acc;
                }, {});
                const preparedChat = {
                  chat_id: data.chat_id,
                  chat_messages: messages,
                  first_name: data.first_name,
                  is_new_chat: data.is_new_chat,
                  last_message: data.last_message,
                  last_message_time: data.last_message_time,
                  last_name: data.last_name,
                  legal_name: data.legal_name,
                  location_first: data.location_first,
                  location_second: data.location_second,
                  offer_id: data.offer_id,
                  offer_type: data.offer_type,
                  ur_name: data.ur_name,
                  phone: data.phone,
                  offer_details: data.offer_details
                };
                store.dispatch(newChatReceived(preparedChat));
              } else {
                const newMessage = message.notify_payload;
                const preparedMessage = {
                  chat_id: parseInt(newMessage.chat_id),
                  created_at: newMessage.created_at,
                  id: parseInt(newMessage.id),
                  is_first_sender: newMessage.message_owner === 0 ? 0 : 1,
                  message: newMessage.message,
                  status: newMessage.status,
                  cargo_offer_id: newMessage.cargo_offer_id,
                  reply_to_id: newMessage.reply_to_id
                };

                if (newMessage.skip_notification) store.dispatch(chatMessageUpdate(preparedMessage));
                else store.dispatch(chatMessageReceived(preparedMessage));
              }
              break;
            case 'chat_user_typing':
              store.dispatch(typingChatMessage(message));
              break;
            case 'chat_message_status_update':
              store.dispatch(chatMessageStatusUpdate(notify_payload));
              break;
            default:
              break;
          }
        } else if (sender_company != null && sender_company == selectedCompany && userId == sender) {
          switch (notify_type) {
            case 'chat_message':
              if (message.notify_payload.chat) {
                store.dispatch(getChats());
              } else {
                const newMessage = message.notify_payload;
                const preparedMessage = {
                  chat_id: parseInt(newMessage.chat_id),
                  created_at: newMessage.created_at,
                  id: parseInt(newMessage.id),
                  is_first_sender: newMessage.message_owner === 0 ? 0 : 1,
                  message: newMessage.message,
                  status: newMessage.status,
                  cargo_offer_id: newMessage.cargo_offer_id,
                  reply_to_id: newMessage.reply_to_id
                };
                store.dispatch(chatMessageUpdate(preparedMessage));
              }
              break;
            case 'chat_message_status_update':
              store.dispatch(chatMessageStatusUpdate(notify_payload));
              break;
            default:
              break;
          }
        }
      });
    }
  };

  onLogout = () => {
    if (config.ENABLE_SOCKET_SERVER) {
      instance.on('logout', () => {
        store.dispatch(logout());
      });
    }
  };

  sendOnlineStatus = () => {
    if (config.ENABLE_SOCKET_SERVER) {
      this.socket.emit('loggedIn', this.state.message, () => this.setState({ message: '' }));
    }
  };
}

class SingletonInstance {
  constructor() {
    return true;
  }
}

export default Socket;
