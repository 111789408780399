export const RESET_APP = 'RESET_APP';
export const RELOAD_REDUX_STATE = 'RELOAD_REDUX_STATE';

// Login & Register
export const API_LOGIN_REQUEST = 'API_LOGIN_REQUEST';
export const API_LOGIN_SUCCESS = 'API_LOGIN_SUCCESS';
export const API_LOGIN_FAILURE = 'API_LOGIN_FAILURE';
export const API_REGISTER_REQUEST = 'API_REGISTER_REQUEST';
export const API_REGISTER_SUCCESS = 'API_REGISTER_SUCCESS';
export const API_REGISTER_FAILURE = 'API_REGISTER_FAILURE';
export const API_REGISTER_MEMBER_REQUEST = 'API_REGISTER_MEMBER_REQUEST';
export const API_REGISTER_MEMBER_SUCCESS = 'API_REGISTER_MEMBER_SUCCESS';
export const API_REGISTER_MEMBER_FAILURE = 'API_REGISTER_MEMBER_FAILURE';
export const API_GET_USER_INVITE_REQUEST = 'API_GET_USER_INVITE_REQUEST';
export const API_GET_USER_INVITE_SUCCESS = 'API_GET_USER_INVITE_SUCCESS';
export const API_GET_USER_INVITE_FAILURE = 'API_GET_USER_INVITE_FAILURE';

// Logical state
export const LOGOUT = 'LOGOUT';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const LOGGED = 'LOGGED';
export const SET_AUTH_NAV_BAR = 'SET_AUTH_NAV_BAR';
export const TOGGLE_AUTH_NAV_BAR = 'TOGGLE_AUTH_NAV_BAR';
export const SET_SIDE_BAR_STATE = 'SET_SIDE_BAR_STATE';
export const SET_LOGIN_SIDE_BAR_STATE = 'SET_LOGIN_SIDE_BAR_STATE';
export const SET_REGISTER_SIDE_BAR_STATE = 'SET_REGISTER_SIDE_BAR_STATE';
export const SET_SIDE_BAR_STATE_REGULATED_BY_SCROLL = 'SET_SIDE_BAR_STATE_REGULATED_BY_SCROLL';
export const SAVE_REGISTER_STATE = 'SAVE_REGISTER_STATE';
export const SET_MIDDLEWARE_ERROR = 'SET_MIDDLEWARE_ERROR';
export const HANDLE_ERRORS = 'HANDLE_ERRORS';
export const SET_IS_USER_VERIFIED = 'SET_IS_USER_VERIFIED';
export const WHOLE_PAGE_LOADER_SHOW = 'WHOLE_PAGE_LOADER_SHOW';
export const WHOLE_PAGE_LOADER_HIDE = 'WHOLE_PAGE_LOADER_HIDE';
export const SET_MIN_VERSION = 'SET_MIN_VERSION';
export const TOO_MANY_REQUESTS_MODAL_SHOW = 'TOO_MANY_REQUESTS_MODAL_SHOW';
export const TOO_MANY_REQUESTS_MODAL_HIDE = 'TOO_MANY_REQUESTS_MODAL_HIDE';

export const SET_WAREHOUSE_PREDEFINED_ADD_DRAWER = 'SET_WAREHOUSE_PREDEFINED_ADD_DRAWER';
export const SET_WAREHOUSE_PREDEFINED_ADD_DRAWER_CALLBACK = 'SET_WAREHOUSE_PREDEFINED_ADD_DRAWER_CALLBACK';
export const CLEAR_WAREHOUSE_PREDEFINED_ADD_DATA = 'CLEAR_WAREHOUSE_PREDEFINED_ADD_DATA';

export const SET_INTERNET_CONNECTION_OFFLINE = 'SET_INTERNET_CONNECTION_OFFLINE';
export const SET_INTERNET_CONNECTION_ONLINE = 'SET_INTERNET_CONNECTION_ONLINE';

// Users
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const DESELECT_COMPANY = 'DESELECT_COMPANY';
export const GET_USER_COMPANIES_REQUEST = 'GET_USER_COMPANIES_REQUEST';
export const GET_USER_COMPANIES_SUCCESS = 'GET_USER_COMPANIES_SUCCESS';
export const GET_USER_COMPANIES_ERROR = 'GET_USER_COMPANIES_ERROR';

export const GET_USER_PROFILE_PICTURE_REQUEST = 'GET_USER_PROFILE_PICTURE_REQUEST';
export const GET_USER_PROFILE_PICTURE_SUCCESS = 'GET_USER_PROFILE_PICTURE_SUCCESS';
export const GET_USER_PROFILE_PICTURE_ERROR = 'GET_USER_PROFILE_PICTURE_ERROR';

export const GET_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_ERROR = 'GET_USER_SETTINGS_ERROR';

export const GET_USER_PERSONAL_INFO_REQUEST = 'GET_USER_PERSONAL_INFO_REQUEST';
export const GET_USER_PERSONAL_INFO_SUCCESS = 'GET_USER_PERSONAL_INFO_SUCCESS';
export const GET_USER_PERSONAL_INFO_ERROR = 'GET_USER_PERSONAL_INFO_ERROR';

export const SET_USER_PLANNER_SETTINGS = 'SET_USER_PLANNER_SETTINGS';

// Vehicle
export const API_GET_COMPANY_VEHICLES_REQUEST = 'API_GET_COMPANY_VEHICLES_REQUEST';
export const API_GET_COMPANY_VEHICLES_SUCCESS = 'API_GET_COMPANY_VEHICLES_SUCCESS';
export const API_GET_COMPANY_VEHICLES_FAILURE = 'API_GET_COMPANY_VEHICLES_FAILURE';

// Vehicle bids
export const API_GET_VEHICLE_TYPES_REQUEST = 'API_GET_VEHICLE_TYPES_REQUEST';
export const API_GET_VEHICLE_TYPES_SUCCESS = 'API_GET_VEHICLE_TYPES_SUCCESS';
export const API_GET_VEHICLE_TYPES_FAILURE = 'API_GET_VEHICLE_TYPES_FAILURE';
export const API_GET_VEHICLE_EQUIPMENTS_REQUEST = 'API_GET_VEHICLE_EQUIPMENTS_REQUEST';
export const API_GET_VEHICLE_EQUIPMENTS_SUCCESS = 'API_GET_VEHICLE_EQUIPMENTS_SUCCESS';
export const API_GET_VEHICLE_EQUIPMENTS_FAILURE = 'API_GET_VEHICLE_EQUIPMENTS_FAILURE';
export const API_GET_VEHICLE_DESCRIPTIONS_REQUEST = 'API_GET_VEHICLE_DESCRIPTIONS_REQUEST';
export const API_GET_VEHICLE_DESCRIPTIONS_SUCCESS = 'API_GET_VEHICLE_DESCRIPTIONS_SUCCESS';
export const API_GET_VEHICLE_DESCRIPTIONS_FAILURE = 'API_GET_VEHICLE_DESCRIPTIONS_FAILURE';

// Cargo
export const API_GET_PACKAGING_METHODS_REQUEST = 'API_GET_PACKAGING_METHODS_REQUEST';
export const API_GET_PACKAGING_METHODS_SUCCESS = 'API_GET_PACKAGING_METHODS_SUCCESS';
export const API_GET_PACKAGING_METHODS_FAILURE = 'API_GET_PACKAGING_METHODS_FAILURE';

// Warehouse
export const API_GET_WAREHOUSE_TYPES_REQUEST = 'API_GET_WAREHOUSE_TYPES_REQUEST';
export const API_GET_WAREHOUSE_TYPES_SUCCESS = 'API_GET_WAREHOUSE_TYPES_SUCCESS';
export const API_GET_WAREHOUSE_TYPES_FAILURE = 'API_GET_WAREHOUSE_TYPES_FAILURE';
export const API_GET_WAREHOUSE_EQUIPMENT_REQUEST = 'API_GET_WAREHOUSE_EQUIPMENT_REQUEST';
export const API_GET_WAREHOUSE_EQUIPMENT_SUCCESS = 'API_GET_WAREHOUSE_EQUIPMENT_SUCCESS';
export const API_GET_WAREHOUSE_EQUIPMENT_FAILURE = 'API_GET_WAREHOUSE_EQUIPMENT_FAILURE';
export const API_SEARCH_MY_WAREHOUSE_OFFERS_SUCCESS = 'API_SEARCH_MY_WAREHOUSE_OFFERS_SUCCESS';
export const API_SEARCH_MY_WAREHOUSE_OFFERS_FAILURE = 'API_SEARCH_MY_WAREHOUSE_OFFERS_FAILURE';
export const API_GET_WAREHOUSE_TEMPERATURE_CONTROL_REQUEST = 'API_GET_WAREHOUSE_TEMPERATURE_CONTROL_REQUEST';
export const API_GET_WAREHOUSE_TEMPERATURE_CONTROL_SUCCESS = 'API_GET_WAREHOUSE_TEMPERATURE_CONTROL_SUCCESS';
export const API_GET_WAREHOUSE_TEMPERATURE_CONTROL_FAILURE = 'API_GET_WAREHOUSE_TEMPERATURE_CONTROL_FAILURE';
export const API_GET_WAREHOUSE_STORAGE_TYPE_REQUEST = 'API_GET_WAREHOUSE_STORAGE_TYPE_REQUEST';
export const API_GET_WAREHOUSE_STORAGE_TYPE_SUCCESS = 'API_GET_WAREHOUSE_STORAGE_TYPE_SUCCESS';
export const API_GET_WAREHOUSE_STORAGE_TYPE_FAILURE = 'API_GET_WAREHOUSE_STORAGE_TYPE_FAILURE';
export const API_GET_WAREHOUSE_STORAGE_UNIT_REQUEST = 'API_GET_WAREHOUSE_STORAGE_UNIT_REQUEST';
export const API_GET_WAREHOUSE_STORAGE_UNIT_SUCCESS = 'API_GET_WAREHOUSE_STORAGE_UNIT_SUCCESS';
export const API_GET_WAREHOUSE_STORAGE_UNIT_FAILURE = 'API_GET_WAREHOUSE_STORAGE_UNIT_FAILURE';
export const API_GET_WAREHOUSE_PRICING_INTERVAL_REQUEST = 'API_GET_WAREHOUSE_PRICING_INTERVAL_REQUEST';
export const API_GET_WAREHOUSE_PRICING_INTERVAL_SUCCESS = 'API_GET_WAREHOUSE_PRICING_INTERVAL_SUCCESS';
export const API_GET_WAREHOUSE_PRICING_INTERVAL_FAILURE = 'API_GET_WAREHOUSE_PRICING_INTERVAL_FAILURE';

// Company
export const COMPANY_SUSPENDED_MODAL_SHOW = 'COMPANY_SUSPENDED_MODAL_SHOW';
export const COMPANY_SUSPENDED_MODAL_HIDE = 'COMPANY_SUSPENDED_MODAL_HIDE';

// Invites
export const API_SEND_EMAIL_INVITE_REQUEST = 'API_SEND_EMAIL_INVITE_REQUEST';
export const API_SEND_EMAIL_INVITE_SUCCESS = 'API_SEND_EMAIL_INVITE_SUCCESS';
export const API_SEND_EMAIL_INVITE_FAILURE = 'API_SEND_EMAIL_INVITE_FAILURE';
export const API_GENERATE_INVITE_LINK_REQUEST = 'API_GENERATE_INVITE_LINK_REQUEST';
export const API_GENERATE_INVITE_LINK_SUCCESS = 'API_GENERATE_INVITE_LINK_SUCCESS';
export const API_GENERATE_INVITE_LINK_FAILURE = 'API_GENERATE_INVITE_LINK_FAILURE';

// Socket
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const NOTIFICATION_NOTIFY_RECEIVED = 'NOTIFICATION_NOTIFY_RECEIVED'; // For ping messages
export const NOTIFICATION_MESSAGE_RECEIVED = 'NOTIFICATION_MESSAGE_RECEIVED'; // For notification dropdown messages
export const NOTIFICATION_NOTIFY_DELETED = 'NOTIFICATION_NOTIFY_DELETED'; // For deleting notification dot messages
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS'; // For deleting all notifications, messages, popups and dots
export const NOTIFICATION_POPUP_DELETED = 'NOTIFICATION_POPUP_DELETED'; // For deleting popup notifications
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const LOAD_MORE_NOTIFICATIONS_REQUEST = 'LOAD_MORE_NOTIFICATIONS_REQUEST';
export const LOAD_MORE_NOTIFICATIONS_SUCCESS = 'LOAD_MORE_NOTIFICATIONS_SUCCESS';
export const LOAD_MORE_NOTIFICATIONS_FAILURE = 'LOAD_MORE_NOTIFICATIONS_FAILURE';
export const SET_NOTIFICATIONS_MENU_VISIBILITY = 'SET_NOTIFICATIONS_MENU_VISIBILITY';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';

// Filter
export const SET_CARGO_OFFERS_FILTER = 'SET_CARGO_OFFERS_FILTER';
export const CLEAR_CARGO_OFFERS_FILTER = 'CLEAR_CARGO_OFFERS_FILTER';
export const CLEAR_CARGO_OFFERS_FILTER_CARGO = 'CLEAR_CARGO_OFFERS_FILTER_CARGO';
export const CLEAR_CARGO_OFFERS_FILTER_ROUTE = 'CLEAR_CARGO_OFFERS_FILTER_ROUTE';
export const CLEAR_CARGO_OFFERS_FILTER_SERVICES = 'CLEAR_CARGO_OFFERS_FILTER_SERVICES';
export const CLEAR_CARGO_OFFERS_FILTER_REQUESTER = 'CLEAR_CARGO_OFFERS_FILTER_REQUESTER';
export const CLEAR_CARGO_OFFERS_FILTER_OFFERS = 'CLEAR_CARGO_OFFERS_FILTER_OFFERS';

export const SET_VEHICLE_OFFERS_FILTER = 'SET_VEHICLE_OFFERS_FILTER';
export const CLEAR_VEHICLE_OFFERS_FILTER = 'CLEAR_VEHICLE_OFFERS_FILTER';
export const CLEAR_VEHICLE_OFFERS_FILTER_ROUTE = 'CLEAR_VEHICLE_OFFERS_FILTER_ROUTE';
export const CLEAR_VEHICLE_OFFERS_FILTER_VEHICLE = 'CLEAR_VEHICLE_OFFERS_FILTER_VEHICLE';
export const CLEAR_VEHICLE_OFFERS_FILTER_ADDITIONAL = 'CLEAR_VEHICLE_OFFERS_FILTER_ADDITIONAL';
export const CLEAR_VEHICLE_OFFERS_FILTER_REQUESTER = 'CLEAR_VEHICLE_OFFERS_FILTER_REQUESTER';
export const CLEAR_VEHICLE_OFFERS_FILTER_OFFERS = 'CLEAR_VEHICLE_OFFERS_FILTER_OFFERS';

export const SET_WAREHOUSE_OFFERS_FILTER = 'SET_WAREHOUSE_OFFERS_FILTER';
export const CLEAR_WAREHOUSE_OFFERS_FILTER = 'CLEAR_WAREHOUSE_OFFERS_FILTER';
export const CLEAR_WAREHOUSE_OFFERS_FILTER_LOCATION = 'CLEAR_WAREHOUSE_OFFERS_FILTER_LOCATION';
export const CLEAR_WAREHOUSE_OFFERS_FILTER_STORAGE = 'CLEAR_WAREHOUSE_OFFERS_FILTER_STORAGE';
export const CLEAR_WAREHOUSE_OFFERS_FILTER_SERVICES = 'CLEAR_WAREHOUSE_OFFERS_FILTER_SERVICES';
export const CLEAR_WAREHOUSE_OFFERS_FILTER_REQUESTER = 'CLEAR_WAREHOUSE_OFFERS_FILTER_REQUESTER';
export const CLEAR_WAREHOUSE_OFFERS_FILTER_OFFERS = 'CLEAR_WAREHOUSE_OFFERS_FILTER_OFFERS';

export const GET_FILTER_NAME_VEHICLE = 'GET_FILTER_NAME_VEHICLE';
export const GET_FILTER_NAME_CARGO = 'GET_FILTER_NAME_CARGO';
export const GET_FILTER_NAME_WAREHOUSE = 'GET_FILTER_NAME_WAREHOUSE';
export const SET_FILTER_NAME_VEHICLE = 'SET_FILTER_NAME_VEHICLE';
export const SET_FILTER_NAME_CARGO = 'SET_FILTER_NAME_CARGO';
export const SET_FILTER_NAME_WAREHOUSE = 'SET_FILTER_NAME_WAREHOUSE';
export const SET_SELECTED_FILTER_VEHICLE = 'SET_SELECTED_FILTER_VEHICLE';
export const SET_SELECTED_FILTER_CARGO = 'SET_SELECTED_FILTER_VEHICLE';
export const SET_SELECTED_FILTER_WAREHOUSE = 'SET_SELECTED_FILTER_VEHICLE';

// Live Chat
export const GET_CHAT_MESSAGES_REQUEST = 'GET_CHAT_MESSAGES_REQUEST';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAILURE = 'GET_CHAT_MESSAGES_FAILURE';
export const GET_CHATS_REQUEST = 'GET_CHATS_REQUEST';
export const GET_CHATS_FAILURE = 'GET_CHATS_FAILURE';
export const CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED';
export const USER_TYPING_IN_CHAT = 'USER_TYPING_IN_CHAT';
export const MARK_MESSAGE_AS_SEEN = 'MARK_MESSAGE_AS_SEEN';
export const GET_ALL_USER_CHATS = 'GET_ALL_USER_CHATS';
export const NEW_MESSAGE_SENT = 'NEW_MESSAGE_SENT';
export const NEW_CHAT_SENT = 'NEW_CHAT_SENT';
export const NEW_CHAT_RECEIVED = 'NEW_CHAT_RECEIVED';
export const CHAT_MESSAGE_UPDATE = 'CHAT_MESSAGE_UPDATE';
export const SET_LIVE_CHAT = 'SET_LIVE_CHAT';
export const REMOVE_LIVE_CHAT = 'REMOVE_LIVE_CHAT';
export const CHAT_MESSAGE_STATUS_UPDATE = 'CHAT_MESSAGE_STATUS_UPDATE';

//Planner
export const SET_RELOAD_PLANNER_DASHBOARD_BY_DATE = 'SET_RELOAD_PLANNER_DASHBOARD_BY_DATE';
export const SET_RELOAD_EVENT_BY_ID = 'SET_RELOAD_EVENT_BY_ID';
export const SET_RELOAD_PLANNER_DASHBOARD = 'SET_RELOAD_PLANNER_DASHBOARD';
export const SET_ACTIVE_HOVER_EVENT_DETAILS = 'SET_ACTIVE_HOVER_EVENT';

//Contacts
export const SET_RELOAD_CONTACTS_DASHBOARD = 'SET_RELOAD_CONTACTS_DASHBOARD';
export const SET_RELOAD_CONTACT = 'SET_RELOAD_CONTACT';

// New users tunnel
export const SET_NEW_USERS_TUNNEL = 'SET_NEW_USERS_TUNNEL';

// Backend time
export const BACKEND_DATE_TIME_REQUEST = 'BACKEND_DATE_TIME_REQUEST';
export const BACKEND_DATE_TIME_SUCCESS = 'BACKEND_DATE_TIME_SUCCESS';
export const BACKEND_DATE_TIME_FAILURE = 'BACKEND_DATE_TIME_FAILURE';
export const ADD_BACKEND_DATE_TIME_LISTENER = 'ADD_BACKEND_DATE_TIME_LISTENER';
export const REMOVE_BACKEND_DATE_TIME_LISTENER = 'REMOVE_BACKEND_DATE_TIME_LISTENER';
export const UPDATE_BACKEND_DATE_TIME = 'UPDATE_BACKEND_DATE_TIME';

// Hot Reload subscribers
export const HOT_RELOAD_SUBSCRIBE = 'HOT_RELOAD_SUBSCRIBE';
export const HOT_RELOAD_UNSUBSCRIBE = 'HOT_RELOAD_UNSUBSCRIBE';
export const HOT_RELOAD_BULK_SUBSCRIBE = 'HOT_RELOAD_BULK_SUBSCRIBE';
export const HOT_RELOAD_BULK_UNSUBSCRIBE = 'HOT_RELOAD_BULK_UNSUBSCRIBE';

// unify constants for handle drawer
// property names in both objects SET_DRAWER_STATE & SET_DRAWER_CALLBACK should be the same for state and callback pair
export const SET_DRAWER_STATE = {
  //offers
  addVehicleOfferDrawer: 'ADD_VEHICLE_OFFER_DRAWER',
  addCargoOfferDrawer: 'ADD_CARGO_OFFER_DRAWER',
  addWarehouseOfferDrawer: 'ADD_WAREHOUSE_OFFER_DRAWER',
  //predefined
  addPredefinedVehicleDrawer: 'ADD_PREDEFINED_VEHICLE_DRAWER',
  addPredefinedCargoDrawer: 'ADD_PREDEFINED_CARGO_DRAWER',
  addPredefinedWarehouseDrawer: 'ADD_PREDEFINED_WAREHOUSE_DRAWER',
  //company
  addCompanyDrawer: 'ADD_COMPANY_OFFER_DRAWER',
  verifiedCompanyDrawer: 'VERIFIED_COMPANY_DRAWER',
  //users
  userInviteDrawer: 'USER_INVITE_DRAWER',
  //documents
  addCompanyDocDrawer: 'ADD_COMPANY_DOC_DRAWER',
  addCmrDrawer: 'ADD_CMR_DRAWER',
  touDrawer: 'TOU_DRAWER',
  ppDrawer: 'PP_DRAWER',
  dpaDrawer: 'DPA_DRAWER',
  faqDrawer: 'FAQ_DRAWER',
  viewDocumentDrawer: 'VIEW_DOCUMENT_DRAWER',
  //contact us
  contactUsDrawer: 'CONTACT_US_DRAWER',
  //chat
  chatsDrawer: 'ALL_CHATS_DRAWER',
  //subscription
  chooseSubscriptionDrawer: 'CHOOSE_SUBSCRIPTION_DRAWER',
  //planer
  addEventPlannerDrawer: 'ADD_EVENT_PLANNER_DRAWER',
  viewEventPlannerDrawer: 'VIEW_EVENT_PLANNER_DRAWER',
  editEventPlannerDrawer: 'EDIT_EVENT_PLANNER_DRAWER',
  recreateEventInPlanner: 'RECREATE_EVENT_PLANNER_DRAWER',
  transportOrderInPlanner: 'RECREATE_TANSPORT_ORDER_PLANNER_DRAWER',
  //contacts
  addContactDrawer: 'ADD_CONTACT_DRAWER',
  viewContactDrawer: 'VIEW_CONTACT_DRAWER',
  editContactDrawer: 'EDIT_CONTACT_DRAWER',
  addContactCategory: 'ADD_CONTACT_CATEGORY',
  //agreements
  viewAgreementDrawer: 'VIEW_AGREEMENT_DRAWER',
  //ratings
  viewRateAgreementDrawer: 'VIEW_RATE_AGREEMENT_DRAWER'
};

export const SET_DRAWER_CALLBACK = {
  //offers
  addVehicleOfferDrawer: 'ADD_VEHICLE_OFFER_DRAWER_CALLBACK',
  addCargoOfferDrawer: 'ADD_CARGO_OFFER_DRAWER_CALLBACK',
  addWarehouseOfferDrawer: 'ADD_WAREHOUSE_OFFER_DRAWER_CALLBACK',
  addCompanyDrawer: 'ADD_COMPANY_OFFER_DRAWER_CALLBACK',
  //predefined
  addPredefinedVehicleDrawer: 'ADD_PREDEFINED_VEHICLE_DRAWER_CALLBACK',
  addPredefinedCargoDrawer: 'ADD_PREDEFINED_CARGO_DRAWER_CALLBACK',
  addPredefinedWarehouseDrawer: 'ADD_PREDEFINED_WAREHOUSE_DRAWER_CALLBACK',
  //users
  userInviteDrawer: 'USER_INVITE_DRAWER_CALLBACK',
  //documents
  addCompanyDocDrawer: 'ADD_COMPANY_DOC_DRAWER_CALLBACK',
  dpaDrawer: 'DPA_DRAWER_CALLBACK',
  //planer
  addEventPlannerDrawer: 'ADD_EVENT_PLANNER_DRAWER_CALLBACK',
  //contacts
  addContactDrawer: 'ADD_CONTACT_DRAWER_CALLBACK'
};

export const SET_DRAWER_DATA = {
  dpaDrawer: 'DPA_DRAWER_DATA',
  chatsDrawer: 'ALL_CHATS_DRAWER_DATA',
  editContactDrawer: 'EDIT_CONTACT_DRAWER_DATA'
};
