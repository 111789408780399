import { SET_DRAWER_STATE, SET_DRAWER_CALLBACK, SET_DRAWER_DATA } from '../../constants/actions';

function setDrawerOpened(drawerType, opened, data) {
  return {
    type: SET_DRAWER_STATE[drawerType],
    opened: opened,
    ...(data && { data })
  };
}

function setCallback(drawerType, callback) {
  return {
    type: SET_DRAWER_CALLBACK[drawerType],
    callback: callback
  };
}

function setData(drawerType, data) {
  return {
    type: SET_DRAWER_DATA[drawerType],
    data: data
  };
}

export function openDrawer(opened, type, data) {
  return (dispatch) => {
    return dispatch(setDrawerOpened(type, opened, data));
  };
}

export function setDrawerCallback(type, callback) {
  return (dispatch) => {
    return dispatch(setCallback(type, callback));
  };
}

export function setDrawerData(type, data) {
  return (dispatch) => {
    return dispatch(setData(type, data));
  };
}
