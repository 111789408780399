const nonAuthenticatedRoutes = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_SUCCESS: 'register/success',
  FORGOT_YOUR_PASSWORD: '/forgotYourPassword',
  PASSWORD_RESET: '/resetPassword/:hash',
  TERMS_OF_USE: '/termsofuse',
  PRIVACY_POLICY: '/privacypolicy',
  DPA: '/DPA'
};

const authenticatedRoutes = {
  DASHBOARD: '/dashboard',
  // Paths for users
  EDIT_PROFILE: '/editProfile',
  TERMS_OF_USE: '/termsofuse',
  PRIVACY_POLICY: '/privacypolicy',
  // Paths for vehicle
  OFFERS_VEHICLE_ACTIVE: '/vehicleMyOffers',
  OFFERS_VEHICLE_BIDS: '/vehicleMyBids',
  OFFERS_VEHICLE: 'vehicleOffers',
  OFFERS_MY_OFFER: '/myVehicleOffer',
  VEHICLE_OFFER: 'agreements/vehicle-agreements', //:id
  // Paths for cargo
  OFFERS_CARGO_ADD: '/cargoAdd',
  OFFERS_CARGO_BID: '/cargoOffer',
  OFFERS_CARGO_ACTIVE: '/cargoMyOffers',
  OFFERS_CARGO_BIDS: '/cargoMyBids',
  OFFERS_CARGO: 'cargoOffers',
  OFFERS_CARGO_MY_OFFER: '/myCargoOffer',
  // Paths for warehouse
  OFFERS_WAREHOUSE_BID: '/warehouseOffer',
  OFFERS_WAREHOUSE_ACTIVE: '/warehouseMyOffers',
  OFFERS_WAREHOUSE_BIDS: '/warehouseMyBids',
  OFFERS_WAREHOUSE: 'warehouseOffers',
  OFFERS_WAREHOUSE_MY_OFFER: '/myWarehouseOffer',
  // Paths for agreements
  VEHICLE_AGREEMENT: '/vehicleAgreement',
  CARGO_AGREEMENT: '/cargoAgreement',
  WAREHOUSE_AGREEMENT: '/warehouseAgreement',
  UNPAID_AGREEMENTS: '/unpaidAgreements',
  // Payment
  BILLING: '/usageAndBilling',
  // Paths for company
  MANAGE_COMPANIES: '/manageCompanies',
  MANAGE_COMPANY_USERS: '/manageCompanyUsers',
  COMPANY_AGREEMENTS: '/companyAgreements',
  VEHICLES: '/vehicles',
  CARGOES: '/cargoes',
  WAREHOUSES: '/warehouses',
  COMPANY_DOCUMENTS: '/companyDocuments',
  DOCUMENT_VIEW: '/documentView',
  //Paths for saved Filters
  SAVED_FILTERS: '/savedFilters',
  // Paths for Planner
  PLANNER_DASHBOARD: '/plannerDashboard',
  // Path for Contacts
  CONTACTS: '/contacts',
  MY_CONTACTS: '/myContacts',
  // Logout
  LOGOUT: '/logout',
  TEMP_OFFERS_BIDS: '/unpublished',
  //Legal documents
  DPA: '/DPA',
  // 404 text fix
  USERS: '/users'
};

const exceptionRoutes = {
  VEHICLE_OFFER: '/vehicleOffer',
  NOT_FOUND_PAGE: '/404page',
  ACCEPT_INVITE: '/invite/accept',
  NOT_ALLOWED: '/not-allowed',
  USER_VERIFICATION: '/users/verification/:hash',
  CONTACT_UNSUBSCRIBE: '/contacts/:contactId/unsubscribe'
};

const nestedlVehicleRoutes = {
  FILTER: 'filter',
  OFFER_RECEIVED_BID: 'submit/:bidId',
  VIEW_BID: 'offer/:offerId/submit/view/:bidId',
  BID_ON_OFFER: 'offer/:offerId/submit',
  VIEW_OFFER: 'offer/:offerId',
  VIEW_VEHICLE: 'vehicle/:vehicleId'
};

const nestedCargoRoutes = {
  FILTER: 'filter',
  OFFER_RECEIVED_BID: 'submit/:bidId',
  VIEW_BID: 'request/:offerId/submit/view/:bidId',
  BID_ON_OFFER: 'request/:offerId/submit',
  VIEW_OFFER: 'request/:offerId',
  VIEW_CARGO: 'cargo/:cargoId'
};

const nestedWarehouseRoutes = {
  FILTER: 'filter',
  OFFER_RECEIVED_BID: 'request/:bidId',
  VIEW_BID: 'offer/:offerId/request/view/:bidId',
  BID_ON_OFFER: 'offer/:offerId/request',
  VIEW_OFFER: 'offer/:offerId',
  VIEW_WAREHOUSE: 'warehouse/:warehouseId'
};

const nestedPlannerRoutes = {
  VIEW_EVENT: 'event/:eventId',
}

const nestedCompanyRoutes = {
  VIEW_COMPANY: 'company/:companyId',
  VIEW_COMPANY_USER: 'user/:userId',
  VIEW_COMPANY_UNREGISTERED_USER: 'user/n/:userId',
  VIEW_DOCUMENT: 'document/:documentId',
  VIEW_VEHICLE_AGREEMENT: 'vehicle-agreement/:agreementId',
  VIEW_CARGO_AGREEMENT: 'cargo-agreement/:agreementId',
  VIEW_WAREHOUSE_AGREEMENT: 'warehouse-agreement/:agreementId',
  RATING_AGREEMENT: 'rating',
  VIEW_TRANSACTIONS: 'transaction/:transactionId'
};

const chatOfferRoutes = {
  VIEW_CARGO_OFFER: 'cargoOffers/request/:offerId',
  VIEW_VEHICLE_OFFER: 'vehicleOffers/offer/:offerId',
  VIEW_WAREHOUSE_OFFER: 'warehouseOffers/offer/:offerId'
};

const nestedTempOfferBidRoutes = {
  VIEW_VEHICLE_OFFER: 'temp/vehicleOffer/:offerId',
  VIEW_CARGO_OFFER: 'temp/cargoRequest/:offerId',
  VIEW_WAREHOUSE_OFFER: 'temp/warehouseOffer/:offerId',
  VIEW_VEHICLE_BID: 'temp/vehicleBid/:offerId/:bidId',
  VIEW_CARGO_BID: 'temp/cargoBid/:offerId/:bidId',
  VIEW_WAREHOUSE_BID: 'temp/warehouseBid/:offerId/:bidId'
};

const nestedPaymentRoutes = {
  MAKE_PAYMENT: 'makePayment',
  MAKE_OVERDUE_PAYMENT: 'oldMakePayment',
  MANAGE_SUBSCRIPTIONS: 'manageSubscriptions',
  INVOICE: 'invoice',
  PAYMENT_METHOD: 'paymentMethod',
  BILLING_INFO: 'billingInfo'
};

const nestedContactRoutes = {
  VIEW_CONTACT: 'contact/:contactType/:contactId/:companyId',
  EDIT_CONTACT: 'contact/:contactType/:contactId/edit',
  REMOVE_CONTACT: 'remove-contact/:contactId',
  REMOVE_EXISTING_CONTACT: 'remove-existing-contact/:contactId/:companyId',
  LINK_EXISTING: '/link-existing-user'
};

export {
  nonAuthenticatedRoutes,
  authenticatedRoutes,
  exceptionRoutes,
  nestedlVehicleRoutes,
  nestedCargoRoutes,
  nestedWarehouseRoutes,
  nestedPlannerRoutes,
  nestedCompanyRoutes,
  nestedTempOfferBidRoutes,
  nestedPaymentRoutes,
  chatOfferRoutes,
  nestedContactRoutes
};
